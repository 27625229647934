<template>
  <div class="sms-login">
    <a-form :model="form" ref="ruleForm" :rules="rules">
      <a-form-item name="phone">
        <a-input-group compact>
          <a-input
            size="large"
            v-model:value="form.phone"
            prefix="手机号"
            placeholder="请输入您的手机号"
          />
        </a-input-group>
      </a-form-item>
      <a-form-item class="smscode" name="code">
        <a-input-group compact>
          <a-input
            style="width: calc(100% - 122px)"
            v-model:value="form.code"
            size="large"
            placeholder="请输入您的验证码"
          />
          <a-button
            class="get-code-btn"
            v-if="startGetCode"
            slot="addonAfter"
            disabled
            >{{ getCodeTimer }}s后重新获取</a-button
          >
          <a-button
            type="primary"
            class="get-code-btn"
            slot="addonAfter"
            @click="getSmsCode"
            v-else
            >获取验证码</a-button
          >
        </a-input-group>
      </a-form-item>
    </a-form>
    <contextHolder />
    <template v-if="!isProd">
      <a-button @click="onTestLogin">测试企业管理员用一键登录</a-button>
      <a-button @click="onStoreTestLogin">测试店长用一键登录</a-button>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import type { UnwrapRef } from 'vue'
import {
  AForm,
  AFormItem,
  AInputGroup,
  AInput,
  AButton,
  message
} from '@/components/commons'
import { phoneNumRules } from '@/utils/validator'
import type { Rule } from 'ant-design-vue/es/form'
import type { PhoneNum, verifyCode } from '../types'
import { phoneLoginAPI, getVerifyCodeAPI, login } from '@/api'
import { userInfoStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import { AuditStatusTypeEum } from '@/types'
import md5 from 'md5'

interface LoginInfoType {
  phone: PhoneNum
  code: verifyCode
}

const userInfo = userInfoStore()
const [messageApi, contextHolder] = message.useMessage()
const isProd = computed(() => import.meta.env.MODE === 'production')
const emit = defineEmits(['update:is-validate'])
const rules: Record<string, Rule[]> = {
  phone: [{ validator: phoneNumRules, trigger: 'blur' }],
  code: [{ required: true, message: '请输入验证码！', trigger: 'blur' }]
}
const form: UnwrapRef<LoginInfoType> = reactive({
  phone: '',
  code: ''
})
const startGetCode = ref(false)
const getCodeTimer = ref(60)
const timer = ref<number | undefined>()
const ruleForm = ref()
const countdown = ref(0)
const router = useRouter()
const disabled = computed(() => !(form.phone && form.code))
watch(form, (n) => {
  emit('update:is-validate', n.phone && n.code)
})
async function getVerificationCode() {
  // 这里可以添加发送请求获取验证码的逻辑
  // 模拟一个定时器来表示倒计时

  countdown.value = 60
  const interval = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--
    } else {
      clearInterval(interval)
    }
  }, 1000)
}
async function getSmsCode() {
  ruleForm.value.validate('phone').then(async () => {
    startGetCode.value = true
    timer.value = setInterval(() => {
      --getCodeTimer.value
      if (getCodeTimer.value <= 0) {
        clearInterval(timer.value)
        timer.value = undefined
        startGetCode.value = false
        getCodeTimer.value = 60
      }
    }, 1000)
    await getVerifyCodeAPI(form.phone)
    messageApi.success('验证码已发送')
  })
}
function onLogin() {
  ruleForm.value.validate().then(async () => {
    const res: any = await phoneLoginAPI({
      loginPhone: form.phone,
      smsCode: form.code,
      clientId: 1
    })
    if (res.data.auditStatus !== 1) {
      const qrCode = res.data.auditQrCodeFileUrl || ''
      const type = res.data.auditStatus
      return router.push(
        `/user/failed?type=${type}&code=${qrCode}${
          type === AuditStatusTypeEum.REJECT ? '&info=' + res.data.auditMsg : ''
        }`
      )
    }
    userInfo.setUserInfo(res.data)
    router.push({ path: '/store' })
    location.reload()
  })
}
async function onTestLogin() {
  const res: any = await login({
    userName: 'zth_test_account',
    password: md5('123123123a'),
    clientId: 1
  })
  if (res.data.auditStatus !== 1) {
    const qrCode = res.data.auditQrCodeFileUrl || ''
    const type = res.data.auditStatus
    return router.push(`/user/failed?type=${type}&code=${qrCode}`)
  }
  userInfo.setUserInfo(res.data)
  router.push({ path: '/store' })
  location.reload()
}
async function onStoreTestLogin() {
  const res: any = await login({
    // userName: '13633371444',
    userName: '13633300000',
    password: md5('123123123a'),
    clientId: 1
  })
  if (res.data.auditStatus !== 1) {
    const qrCode = res.data.auditQrCodeFileUrl || ''
    const type = res.data.auditStatus
    const auditMsg = res.data.auditMsg
    return router.push(
      `/user/failed?type=${type}&info=${auditMsg}&code=${qrCode}`
    )
  }
  await userInfo.setUserInfo(res.data)
  router.push({ path: '/store' })
  location.reload()
}
defineExpose({
  onLogin
})
</script>

<style lang="scss">
.smscode {
  .ant-form-item-control-input-content {
    display: flex;
  }
  .ant-input {
    border-radius: 8px 0 0 8px;
    border-right: 0;
  }
  .ant-btn {
    height: 50px;
  }
}
.sms-login {
  margin-bottom: 80px;
  .ant-form {
    margin-top: 32px !important;
  }
}
//   .ant-input-affix-wrapper .ant-input:not(:first-child) {
//     padding-left: 60px !important;
//   }
// }
.ant-input-group-addon {
  padding: 0;
}
.get-code-btn {
  width: 122px;
  border-radius: 0 6px 6px 0;
  background-color: #165dff;
}
</style>
