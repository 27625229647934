import BigNumber from 'bignumber.js'

type Format = {
  group?: boolean
  decimalPlaces?: number
  percentage?: boolean
  prefix?: string
  suffix?: string
}

const defaultFmt: Format = {
  group: false,
  decimalPlaces: undefined,
  percentage: false
}

export function formatNumber(n: number, fmt: Format = defaultFmt) {
  let bn = new BigNumber(n)
  const { group, decimalPlaces, percentage, prefix, suffix } = {
    ...defaultFmt,
    ...fmt
  }
  const realDecimalPlaces =
    typeof decimalPlaces === 'number'
      ? decimalPlaces
      : percentage === true
      ? 2
      : undefined
  const isDecimalPlaces = typeof realDecimalPlaces === 'number'
  const bnFmt: BigNumber.Format = {
    decimalSeparator: '.',
    prefix,
    suffix: typeof suffix === 'string' ? suffix : percentage ? '%' : ''
  }
  if (percentage === true) {
    bn = bn.times(100)
  }
  if (group === true) {
    bnFmt.groupSeparator = ','
    bnFmt.groupSize = 3
  }

  return isDecimalPlaces
    ? bn.toFormat(realDecimalPlaces, bnFmt)
    : bn.toFormat(bnFmt)
}
