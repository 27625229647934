// store.ts
import type {
  SmartVendDetailType,
  StaffInfo,
  UserInfo,
  MaterialType,
  BackgroundOrForeground
} from '@/types'

export enum VideoType {
  /**商品视频 */
  GOODS = 1,
  /**场景视频 */
  SCENE
}
export enum LLMSwitch {
  CLOSED,
  OPENED
}
export enum StoreStatus {
  OFFLINE,
  INPROGRESS
}
export enum Permission {
  PUBLIC,
  PRIVATE
}
/**商品图片/视频播放模式 */
export enum PlayModel {
  /**静态 */
  STATIC,
  /**轮播 */
  CAROUSEL
}
/**商品视频声音模式 */
export enum VideoVoiceModel {
  MUTED,
  NON_MUTED
}
export enum LayerTypeEnum {
  BACKGROUND = 1,
  /**形象 */
  CHARACTER,
  PICTURE,
  VIDEO,
  ELEMENT
}
/**素材类型 */
export enum MediaTypeEnum {
  /**非商品素材涂层或商品素材图层为空 */
  NONE_MEDIA,
  PICTURE,
  PATCH
}
export enum MaterialFlieType {
  PIC = 1,
  VIDEO
}
export interface Layer {
  sourceId: string
  videoLayerId?: string
  layerId?: string
  businessId: UserInfo['businessId']
  layerName: string
  imgModel?: PlayModel
  videoVoiceModel?: VideoVoiceModel
  videoModel?: PlayModel
  type: LayerTypeEnum
  sort: number
  materialId: string
  materialPreviewUrl: string
  position: string
  productMediaType: MediaTypeEnum
  tenantId?: string
  createUser?: string
  createTime?: Date
  updateUser?: string
  updateTime?: Date
}
export interface VideoTemplate {
  videoTemplateId?: string
  businessId: string
  modelId: string
  voiceId: string
  videoTemplateName?: string
  des?: string
  tenantId?: string
  createUser?: string
  permission: Permission
  createTime?: Date
  updateUser?: string
  updateTime?: Date
  layers: Layer[]
}
export interface StoreInfo {
  storeImageId?: string
  storeImageUrl?: string
  storeId: string
  llmEnable: LLMSwitch
  storeName: string
  provinceLocationId: string
  cityLocationId: string
  districtLocationId: string
  storeAddress: string
  storeDesc: string
  districtLocationIdDesc?: string
  cityLocationIdDesc?: string
  provinceLocationIdDesc?: string
}
export type StoreDetailType = StoreInfo & {
  storeStatusDesc?: string
  districtLocationIdDesc: string
  updateTime: Date
  storeStatus: StoreStatus
  videoTemplateVO: VideoTemplate
  employeeVOList: StaffInfo[]
  smartVendingMachineVOList: SmartVendDetailType[]
}
export enum UpdateVideoFlag {
  NOT_UPDATE,
  UPDATE
}
export type UpdateStore = StoreInfo & {
  videoTemplate: VideoTemplate & { updateVideoFlag: UpdateVideoFlag }
  userIdList: StaffInfo[]
  smartVendingMachineIdList: SmartVendDetailType[]
}
export interface QueryMaterialRes {
  materialId: string
  businessId: string
  materialName: string
  materialFileId: string
  materialFileUrl: string
  previewFileId: string
  previewFileUrl: string
  materialFileType: Omit<MaterialType, 'AUDIO'>
  materialType: BackgroundOrForeground
  permission: Permission
  tenantId: string
  createUser: string
  createTime: Date
  updateUser: string
  updateTime: Date
}
export interface AddStoreParams {
  llmEnable: LLMSwitch
  storeName: string
  storeImageId: string
  storeImageUrl?: string
  provinceLocationId: string
  cityLocationId: string
  districtLocationId: string
  storeAddress: string
  storeDesc: string
  userIdList: string[]
  smartVendingMachineIdList: string[]
  videoTemplateVO: VideoTemplate
}
export interface StoreTable {
  storeId: string
  storeName: string
  provinceLocationId: string
  cityLocationId: string
  districtLocationId: string
  districtLocationIdDesc: string
  storeAddress: string
  ownerName: string
  numberOfSmartVendingMachines: number
  numberOfMerchandise: number
  numberOfScene: number
  numberOfEmployees: number
  updateTime: Date
}
