// store.ts
import { post, get, del, type BaseResponse } from '@/service/http'
import type {
  StoreDetailType,
  Scene,
  GoodsInfo,
  VideoType,
  VideoTemplate,
  StoreInfo,
  SmartVendDetailType,
  StaffInfo,
  ListBaseType,
  ListReqBase,
  SceneVideo,
  SceneLevel,
  LLMSwitch,
  MaterialType,
  BackgroundOrForeground,
  Permission,
  LayerTypeEnum,
  QueryMaterialRes,
  AddStoreParams,
  SimpleGoodsInfo,
  GoodsVideo,
  SimpleScene,
  StoreStatus,
  StoreTable,
  UpdateStore,
  MaterialFlieType
} from '@/types'
import { userInfoStore } from '@/stores/user'
import ROLE from '@/config/role'

type StoreId = StoreInfo['storeId']
export function queryStoreDetailAPI(storeId: string) {
  return get<BaseResponse<StoreDetailType>>(
    `/smartvend/smartvend-terminal/v1/web/terminal/store/detail?storeId=${storeId}`
  )
}
export function deleteStoreAPI(storeId: string) {
  return del('/smartvend/smartvend-terminal/v1/web/terminal/store/delete', {
    params: {
      storeId
    }
  })
}

export function addStoreAPI(data: AddStoreParams) {
  return post('/smartvend/smartvend-terminal/v1/web/terminal/store/add', data)
}
interface storeAddSceneReq {
  storeId: StoreId
  sceneIdList: Scene['sceneId'][]
}
export function storeAddSceneAPI(data: storeAddSceneReq) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/addScene',
    data
  )
}

interface storeAddGoodsReq {
  storeId: StoreId
  addStoreMerchandiseItemDTOList: [
    {
      merchandiseId: GoodsInfo['merchandiseId']
      merchandiseBusinessId: GoodsInfo['merchandiseBusinessId']
    }
  ]
}
export function storeAddGoodsSceneAPI(data: storeAddGoodsReq) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/deleteScene',
    data
  )
}
interface stroreSyncVideoReq {
  storeId: StoreId
  videoType: VideoType
  updateStoreVideoSyncItemDTOList: {
    sourceId: string
    videoId: string
  }[]
}
export function storeSyncVideoAPI(data: stroreSyncVideoReq) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/videoSync',
    data
  )
}

export function updateStoreInfoAPI(data: UpdateStore) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/update',
    data
  )
}

export interface UpdatemartVendReq {
  storeId?: StoreId
  llmEnable: LLMSwitch
  smartVendingMachineIdList: SmartVendDetailType['smartVendingMachineId'][]
}
export function updateStoreSmartVendAPI(data: UpdatemartVendReq) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/updateSmartVendingMachine',
    data
  )
}
export interface UpdateVideoReq {
  storeId: StoreId
  videoType: VideoType
  updateStoreVideoItemDTOList: {
    sourceId: string
    videoId: string
  }[]
}
export function updateVideoAPI(data: UpdateVideoReq) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/updateVideo',
    data
  )
}
interface UpdateStoreStaffReq {
  storeId: StoreId
  llmEnable: LLMSwitch
  userIdList: StaffInfo['userId'][]
}
export function updateStoreStaffAPI(data: UpdateStoreStaffReq) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/updateEmployee',
    data
  )
}

export function queryStoreSmartVendListAPI(
  data: ListReqBase<{
    storeId?: StoreId
    smartVendingMachineName?: SmartVendDetailType['smartVendingMachineName']
  }>
) {
  return post<ListBaseType<SmartVendDetailType>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/smartVendingMachineList',
    data
  )
}
export function queryStoreSceneVideoListAPI(
  data: ListReqBase<{ storeId: StoreId }>
) {
  return post<BaseResponse<ListBaseType<SceneVideo>>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/sceneVideoDetailList',
    data
  )
}
export function queryStoreGoodsVideoListAPI(
  data: ListReqBase<{ storeId: StoreId }>
) {
  return post<BaseResponse<ListBaseType<GoodsVideo>>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/merchandiseVideoDetailList',
    data
  )
}
export function deleteStoreVideoAPI(data: {
  storeId: StoreId
  videoType: VideoType
  sourceIdList: string[]
}) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/deleteVideo',
    data
  )
}
export function queryStoreStaffListAPI(
  data: ListReqBase<{ storeId?: StoreId; realName?: string }>
) {
  return post<BaseResponse<ListBaseType<StaffInfo>>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/employeeList',
    data
  )
}
enum SceneBindingStatus {
  UNBOUND,
  BOUND
}
interface StoreScene {
  sceneId: Scene['sceneId']
  sceneLevelOne: SceneLevel
  sceneLevelTwo: SceneLevel
  sceneType: Scene['sceneType']
  sceneContent: string
  bindingStatus: SceneBindingStatus
}
export function queryStoreSceneListAPI(
  data: ListReqBase<{
    storeId: StoreId
    sceneLevelOne: SceneLevel
    sceneLevelTwo: SceneLevel
  }>
) {
  return post<ListBaseType<StoreScene>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/sceneList'
  )
}

interface UpdateMaterialParams {
  businessId: string
  materialName: string
  materialFileId: string
  previewFileId: string
  materialFileType: MaterialFlieType
  materialType: BackgroundOrForeground
  permission: Permission
}
export function updatedVideoMaterialAPI(data: UpdateMaterialParams) {
  return post('/smartvend/smartvend-capital/v1/material/addMaterial', data)
}
interface QueryMaterialParams {
  businessId: string
  materialId?: string
  materialType: BackgroundOrForeground
  materialFileType?: MaterialFlieType
  permission: Permission
}

export function queryVideoMaterialAPI(data: QueryMaterialParams) {
  return post<BaseResponse<QueryMaterialRes[]>>(
    '/smartvend/smartvend-capital/v1/material/findMaterialList',
    data
  )
}
export function deleteVideoMaterialAPI(id: string) {
  return get(
    `/smartvend/smartvend-capital/v1/material/delMaterial?videoMaterialId=${id}`
  )
}

interface StoreListType {
  storeId: string
  storeName: string
  provinceLocationId: string
  cityLocationId: string
  districtLocationId: string
  districtLocationIdDesc: string
  storeAddress: string
  ownerName: string
  numberOfSmartVendingMachines: 0
  numberOfMerchandise: 0
  numberOfScene: 0
  numberOfEmployees: 0
  updateTime: Date
}
export function queryStoreListAPI(storeName: {} | ListReqBase<string>) {
  const userInfo = userInfoStore()
  if (userInfo.info?.roleId === ROLE.STORE_ADMIN_ROLE_ID) {
    return post<BaseResponse<{ storeId: string; storeName: string }[]>>(
      '/smartvend/smartvend-terminal/v1/web/terminal/store/ownerDetailList',
      storeName
    )
  } else {
    return post<BaseResponse<ListBaseType<StoreListType>>>(
      '/smartvend/smartvend-terminal/v1/web/terminal/store/adminDetailList',
      storeName
    )
  }
}
export function updateVideoTemplateAPI(data: {
  storeId: StoreId
  llmEnable: LLMSwitch
  videoTemplate: VideoTemplate
}) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/updateVideoTemplate',
    data
  )
}
export function qeuryStoreGoodsListAPI(
  data: ListReqBase<{
    storeId: StoreId
    merchandiseChannel?: string
    merchandiseTitle?: string
  }>
) {
  return post<BaseResponse<ListBaseType<SimpleGoodsInfo>>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/merchandiseList',
    data
  )
}

export function addStoreGoodsAPI(data: {
  storeId: StoreId
  addStoreMerchandiseItemDTOList: {
    merchandiseId: string
    merchandiseBusinessId: string
  }[]
}) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/addMerchandise',
    data
  )
}
export function addStoreSceneAPI(data: {
  storeId: StoreId
  sceneIdList: string[]
}) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/addScene',
    data
  )
}
export function qeuryStoreSceneListAPI(
  data: ListReqBase<{
    storeId: StoreId
    sceneLevelOne?: string
    sceneLevelTwo?: string
  }>
) {
  return post<BaseResponse<ListBaseType<SimpleScene>>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/sceneList',
    data
  )
}
export function changeStoreStatus(data: {
  storeId: StoreId
  onlineStatus: StoreStatus
}) {
  return post(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/onlineOfflineOperation',
    data
  )
}
/**企业管理员查询门店 */
export function queryAdminStoreListAPI(
  data: ListReqBase<{ storeName?: string; realName?: string }>
) {
  return post<BaseResponse<ListBaseType<StoreTable[]>>>(
    '/smartvend/smartvend-terminal/v1/web/terminal/store/adminDetailList',
    data
  )
}
