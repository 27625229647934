<template>
  <a-config-provider :locale="zhCN">
    <div style="" class="globalBg" :class="{ loginBg: isLoginPage }">
      <router-view :key="Math.random()" />
    </div>
    <VueQueryDevtools />
    <!-- <RouterView /> -->
  </a-config-provider>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { ConfigProvider as AConfigProvider } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import 'dayjs/locale/zh-cn'

// import { domTitle, setDocumentTitle } from '@/utils/domUtil'
// import { i18nRender } from '@/locales'
// import { API_bindAuthToken } from '@/api/userList.js'
// import { getParameterByName } from '@/utils/util.js'
const route = useRoute()
const debounce = (fn, delay) => {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}
const isLoginPage = computed(() =>
  ['/', '/user', '/user/login', '/user/register', '/user/failed'].includes(
    route.path
  )
)
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
// onBeforeMount(() => {
// if (
//   route.path === '/' ||
//   route.path === '/login' ||
//   route.path === 'user/register'
// ) {
//   isLoginPage.value = true
// } else {
//   isLoginPage.value = false
// }
// localStorage.setItem('lang', 'zh-CN')
// localStorage.setItem('app_language', 'zh-CN')
// })
// const locale = computed(() => {
//   const { title } = this.$route.meta
//       title && setDocumentTitle(`${i18nRender(title)} - ${domTitle}`)

//       return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
// })
</script>
<style lang="scss">
* {
  box-sizing: border-box;
}
/* .ant-menu-root {
  height: calc(100% - 64px) !important;
} */
.ant-layout.sidemenu {
  min-height: 100% !important;
}
.ant-pro-grid-content {
  padding-bottom: 10px;
}
.ant-card-head-title {
  font-weight: 800;
}
.ant-layout-footer {
  display: none;
}
.ant-modal div[aria-hidden='true'] {
  display: none !important;
}
.globalBg {
  background: url('./assets/images/bg.png') repeat left center;
  background-size: cover;
}
.loginBg {
  width: 100%;
  height: 100%;
  background: url('./assets/images/bg@2x.png') no-repeat left center;
  background-size: cover;
}
.ant-notification {
  z-index: 999999999 !important;
}
.roll-back {
  cursor: pointer;
  margin-right: 8px;
}
.ant-segmented {
  .ant-segmented-item-selected {
    color: var(--color-primary);
  }
}
</style>
