import { formatNumber } from './number'
// 比例转换
function calculateRatioX(parentWidth: number, x: number) {
  const xWidth = Math.round((1080 / parentWidth) * x)
  return xWidth
}
function calculateRatioY(parentHeight: number, y: number) {
  const yHeight = Math.round((1920 / parentHeight) * y)
  return yHeight
}
// 比例转换反转
function reversalCalculateRatioX(parentWidth: number, x: number) {
  const xWidth = Math.round((parentWidth / 1080) * x)
  return xWidth
}
function reversalCalculateRatioY(parentHeight: number, y: number) {
  const yHeight = Math.round((parentHeight / 1920) * y)
  return yHeight
}

export function XYWHToPosition(
  {
    x,
    y,
    w,
    h
  }: {
    x: number
    y: number
    w: number
    h: number
  },
  parentWidth: number,
  parentHeight: number
) {
  const position = [
    [calculateRatioX(parentWidth, x), calculateRatioY(parentHeight, y)],
    [calculateRatioX(parentWidth, x + w), calculateRatioY(parentHeight, y)],
    [calculateRatioX(parentWidth, x + w), calculateRatioY(parentHeight, y + h)],
    [calculateRatioX(parentWidth, x), calculateRatioY(parentHeight, y + h)]
  ]
  return position
}

export function positionToXYWH(
  position: number[][],
  parentWidth: number,
  parentHeight: number
) {
  // 取第一个点作为左上角坐标
  const [leftTopX, leftTopY] = position[0]
  // 取第三个点作为右下角坐标
  const [rightBottomX, rightBottomY] = position[2]

  // 转回原始的x、y坐标
  const originalX = reversalCalculateRatioX(parentWidth, leftTopX)
  const originalY = reversalCalculateRatioY(parentHeight, leftTopY)

  // 计算宽和高
  const width = reversalCalculateRatioX(parentWidth, rightBottomX - leftTopX)
  const height = reversalCalculateRatioY(parentHeight, rightBottomY - leftTopY)
  return {
    x: originalX,
    y: originalY,
    w: width,
    h: height
  }
}
export function getImageDimensions(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image()

    // 监听图片加载完成
    img.onload = () => {
      resolve({
        width: 100,
        height: Number(
          formatNumber(img.naturalHeight * (100 / img.naturalWidth), {
            decimalPlaces: 0
          })
        )
      })
    }

    // 监听图片加载失败
    img.onerror = (error) => {
      reject(new Error('Failed to load image: ' + url))
    }

    // 设置图片的 src 属性
    img.src = url
  })
}
const imageExtensions = [
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.bmp',
  '.svg',
  '.webp'
]
// 定义支持的视频文件扩展名数组
const videoExtensions = [
  '.mp4',
  '.avi',
  '.mkv',
  '.mov',
  '.wmv',
  '.flv',
  '.webm'
]

export function isImageOrVideo(
  filePath: string
): 'image' | 'video' | 'unknown' {
  const paths = filePath.split('/')
  const lowerCasePath = paths[paths.length - 1].toLowerCase()
  const fileExtension = lowerCasePath.substring(lowerCasePath.lastIndexOf('.'))
  if (
    imageExtensions.includes(fileExtension) ||
    imageExtensions.includes('.' + fileExtension)
  ) {
    return 'image'
  } else if (videoExtensions.includes(fileExtension)) {
    return 'video'
  }
  return 'unknown'
}
export default {
  calculateRatioX,
  calculateRatioY,
  reversalCalculateRatioX,
  reversalCalculateRatioY,
  isImageOrVideo
}
