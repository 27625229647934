<template>
  <div class="register">
    <div class="steps">
      <a-steps :current="currentStep" :items="stepsItem" size="small" />
    </div>
    <a-form ref="ruleFormRef" size="large" :model="form" :rules="rules">
      <template v-if="currentStep === 0">
        <a-form-item name="userName">
          <a-input
            size="large"
            v-model:value="form.userName"
            :placeholder="accountRule.placeholder"
            prefix="账号"
          />
        </a-form-item>
        <a-form-item name="password">
          <!-- <a-input-password size="large" v-model:value="form.password" placeholder="6 - 16位密码,区分大小写" /> -->
          <a-input-password
            prefix="密码"
            size="large"
            v-model:value="form.password"
            :placeholder="passwordRule.placeholder"
          />
        </a-form-item>
        <a-form-item name="towPassWord" class="tow-Pw">
          <a-input-password
            prefix="确认密码"
            size="large"
            v-model:value="form.towPassWord"
            placeholder="请确认您的密码"
          />
        </a-form-item>
        <a-form-item name="loginPhone" class="therr-text">
          <a-input
            size="large"
            placeholder="请输入您的手机号"
            v-model:value="form.loginPhone"
            prefix="手机号"
          >
          </a-input>
        </a-form-item>
        <a-form-item name="smsCode" class="get-code">
          <a-input-group compact>
            <a-input
              style="width: calc(100% - 122px)"
              size="large"
              placeholder="请输入您的验证码"
              v-model:value="form.smsCode"
            />
            <a-button
              size="large"
              class="get-code-btn"
              v-if="state.smsSendBtn"
              slot="addonAfter"
              disabled
              style="font-size: 14px"
              >{{ state.time }}s后重新获取</a-button
            >
            <a-button
              size="large"
              type="primary"
              class="get-code-btn"
              slot="addonAfter"
              @click.stop.prevent="getCaptcha"
              v-else
              >获取验证码</a-button
            >
          </a-input-group>
        </a-form-item>
        <a-form-item name="invitationCode" class="therr-text">
          <a-input
            size="large"
            v-model:value="form.invitationCode"
            placeholder="请输入您的邀请码"
            prefix="邀请码"
          />
        </a-form-item>
      </template>

      <template v-else>
        <a-form-item name="businessName" size="large">
          <a-input
            size="large"
            v-model:value="form.businessName"
            placeholder="请使用您的品牌店铺名称进行注册"
            prefix="企业名称"
          ></a-input>
        </a-form-item>
        <a-form-item class="license" size="large" name="businessLicenceFileId">
          <div class="license-box">
            <div class="license-box-title">营业执照</div>
            <div class="license-box-des">
              如果您是数智柜商户，请务必上传清晰且无遮挡的营业执照，否则将会审核不通过
            </div>
            <!-- class="license-pic" -->
            <a-upload
              style="display: block; height: 78px"
              v-if="!fileList.length"
              :before-upload="onBeforeUpload"
              v-model:file-list:fileList="fileList"
              :custom-request="handleChange"
              :showUploadList="false"
              accept=".jpg,.jpeg,.png"
            >
              <template v-if="fileValue.fileUrl">
                <img
                  style="width: 100%; height: 100%; border-radius: 8px"
                  :src="fileValue.fileUrl"
                  alt="营业执照"
                />
                <a-button
                  size="small"
                  class="delete-btn"
                  @click.stop="onDeleteFile"
                >
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                </a-button>
              </template>

              <loading-outlined v-else-if="uploading"></loading-outlined>
              <plus-outlined v-else />
            </a-upload>
            <div v-else class="license-pic"></div>
          </div>
        </a-form-item>
      </template>
      <a-form-item class="register-btn-box">
        <div class="register-btn">
          <template v-if="currentStep === 0">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="register-button"
              :loading="registerBtn"
              @click="toNext"
              >{{ buttonText }}
            </a-button>
            <!-- :disabled="() => ruleFormRef.validateFields()" -->
            <!-- @click.stop.prevent="handleSubmit" -->
          </template>
          <template v-else>
            <div class="safety">
              <a-checkbox v-model:checked="agree" />
              <span
                >已阅读并接受元客智播平台
                <a
                  href="https://metaworks-lingyunguang.obs.cn-north-4.myhuaweicloud.com:443/smartvend%2F2025-02%2F11%2F6bea9c1a15474acea09f42b5920e2cf5.docx"
                  >服务协议</a
                >
                与
                <a
                  href="https://metaworks-lingyunguang.obs.cn-north-4.myhuaweicloud.com:443/smartvend%2F2025-02%2F11%2F48850fda0ac94f4fae8fcd49956bbe12.docx"
                  >隐私协议</a
                ></span
              >
            </div>
            <div class="finally-btn">
              <a-button
                size="large"
                htmlType="submit"
                class="register-button"
                style="
                  background-color: #fff;
                  border: 1px solid #165dff;
                  color: #165dff;
                "
                :loading="registerBtn"
                @click.stop.prevent="toPrevious"
                :disabled="registerBtn"
              >
                上一步
              </a-button>
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="register-button"
                :loading="registerBtn"
                @click.stop.prevent="handleSubmit"
                >完成
              </a-button>
            </div>
          </template>
          <router-link style="margin-top: 16px" :to="{ name: 'login' }"
            >已有账号,去登录</router-link
          >
        </div>
      </a-form-item>
    </a-form>
    <!-- <div v-else class="corporate-information">
      <a-form :model="corporateInfo">
        <a-form-item size="large" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            size="large"
            v-model:value="corporateInfo.corporateName"
            placeholder="请使用您的品牌店铺名称进行注册"
            prefix="企业名称"
          ></a-input>
        </a-form-item>
      </a-form>
    </div> -->
    <contextHolder />
  </div>
</template>
<script setup lang="ts">
import { ref, reactive, watch, onBeforeMount, computed } from 'vue'
import type { UnwrapRef } from 'vue'
import {
  ASteps,
  AForm,
  AFormItem,
  AButton,
  AInput,
  AInputPassword,
  AInputGroup,
  AUpload,
  message
} from '@/components/commons'
import type { Rule } from 'ant-design-vue/es/form'
import type { FormValidatorFunction } from '@/basic'
import type { PhoneNum } from './types'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { checkFileType } from '@/utils/file'
import { passwordRule, accountRule } from './constants'
import {
  uploadFileAPI,
  signUpUserAPI,
  getResgisterVerifyCodeAPI,
  resgisterEditVerifyCodeAPI,
  refreshRegisterUerAPI
} from '@/api'
import type { UserRegisterUseInvitationCodeReq } from '@/types'
//@ts-ignore
import md5 from 'md5'
//@ts-ignore
import cloneDeep from 'lodash-es/cloneDeep'
import { useRouter, useRoute } from 'vue-router'

interface CorporateInfo {
  corporateName: string
}

const router = useRouter()
const route = useRoute()
const editRegisterInfo = JSON.parse((route.query?.info as string) || '{}')
const [messageApi, contextHolder] = message.useMessage()
const fileList = ref<File[]>([])
const uploading = ref(false)
const stepsItem = [{ title: '账号信息' }, { title: '企业信息' }]
const currentStep = ref(0)
const labelCol = { span: 4 }
const wrapperCol = { span: 14 }
const ruleFormRef = ref()
const form: UnwrapRef<
  UserRegisterUseInvitationCodeReq & { towPassWord?: string }
> = reactive({
  businessName: '',
  loginPhone: '',
  password: '',
  towPassWord: '',
  invitationCode: '',
  smsCode: '',
  businessLicenceFileId: '',
  qrCodeFileId: ''
})
const corporateInfo: UnwrapRef<CorporateInfo> = reactive({
  corporateName: ''
})
const state = reactive({
  time: 60,
  level: 0,
  smsSendBtn: false,
  passwordLevel: 0,
  passwordLevelChecked: false,
  percent: 10,
  progressColor: '#FF0000'
})
const agree = ref(false)
const isRefresh = ref(route.query?.info)
const verifyLoading = ref(false)
const buttonText = ref('下一步')
const registerBtn = ref(false)
const fileValue = ref<{
  fileId: string
  fileName: string
  fileUrl: string
}>({
  fileId: '',
  fileName: '',
  fileUrl: ''
})
const passwordValidate: FormValidatorFunction = (rule, value) => {
  return new Promise((resolve, reject) => {
    if (!value) {
      reject('请输入密码')
      return
    }
    form.password === value ? resolve('通过') : reject('两次输入密码不一致')
  })
}
const rules: Record<string, Rule[]> = {
  userName: [
    { required: true, validator: accountRule.validate, trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入登录密码', trigger: 'blur' },
    {
      pattern: passwordRule.reg,
      message: passwordRule.prompt.errorInput,
      trigger: 'blur'
    }
  ],
  towPassWord: [
    { message: '请输入密码', trigger: 'blur' },
    // {
    //   pattern: passwordRule.reg,
    //   message: passwordRule.prompt.errorInput,
    //   trigger: 'blur'
    // },
    {
      required: true,
      validator: passwordValidate,
      trigger: ['change', 'blur']
    }
  ],
  invitationCode: [
    { required: true, message: '请输入邀请码', trigger: 'blur' }
  ],
  smsCode: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { len: 6, message: '请输入6位验证码', trigger: 'blur' }
  ],
  businessName: [
    { required: true, message: '请输入企业名称', trigger: 'blur' }
  ],
  businessLicenceFileId: [
    { required: true, message: '请上传营业执照', trigger: 'blur' }
  ]
}
const handleChange = (params: { file: File } & any) => {
  uploadFileAPI(params.file as File).then((res) => {
    form.businessLicenceFileId = res.data.fileId
    fileValue.value.fileId = res.data.fileId
    fileValue.value.fileName = res.data.fileName
    fileValue.value.fileUrl = res.data.fileUrl
    uploading.value = false
  })
  // if (info.file.status === 'uploading') {
  //   loading.value = true
  //   return
  // }
  // if (info.file.status === 'done') {
  // }
  // if (info.file.status === 'error') {
  //   loading.value = false
  //   message.error('upload error')
  // }
}

function onBeforeUpload(file: File) {
  uploading.value = true
  const res = checkFileType(file, ['jpg', 'png', 'jpeg'])
  if (!res) {
    fileList.value = []
    uploading.value = false
    return Promise.reject(messageApi.error('文件格式错误'))
  } else {
    return Promise.resolve(true)
  }
}
function getCaptcha() {
  ruleFormRef.value.validate('loginPhone').then(async () => {
    state.smsSendBtn = true
    const interval = window.setInterval(() => {
      if (state.time-- <= 0) {
        state.time = 60
        state.smsSendBtn = false
        window.clearInterval(interval)
      }
    }, 1000)
    if (isRefresh.value) {
      await resgisterEditVerifyCodeAPI(form.loginPhone)
    } else {
      await getResgisterVerifyCodeAPI(form.loginPhone)
    }
    messageApi.success('验证码已发送')
  })
}
function onDeleteFile() {
  fileList.value = []
  fileValue.value = {
    fileId: '',
    fileUrl: '',
    fileName: ''
  }
}
function getRegisterSmsCode(data: {
  phone: PhoneNum
}): Promise<{ code: number }> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ code: 1 })
    }, 2000)
  })
}
function handleSubmit() {
  if (!agree.value)
    return messageApi.warning('请先阅读并同意服务协议与隐私协议')
  ruleFormRef.value.validate().then(async () => {
    registerBtn.value = true
    let params = cloneDeep(form)
    params.password = md5(params.password)
    delete params.towPassWord
    // const hide = message.loading('注册中..', 0)
    // hide()
    try {
      if (isRefresh.value) {
        params = {
          ...params,
          userId: editRegisterInfo.userId,
          businessId: editRegisterInfo.businessId,
          businessLicenceFileId: editRegisterInfo.businessLicenceFileId
        }
        await refreshRegisterUerAPI(params)
      } else {
        await signUpUserAPI(params)
      }
      message.success('注册成功')
      router.push('/')
    } catch (error) {}
    registerBtn.value = false
  })
}
function toNext() {
  ruleFormRef.value.validate().then(() => {
    currentStep.value = 1
  })
}
function toPrevious() {
  currentStep.value = 0
}
onBeforeMount(() => {
  if (isRefresh.value) {
    // form =
    const info = editRegisterInfo
    form.userName = info.userName
    form.invitationCode = info.invitationCode
    form.businessName = info.businessName
    form.loginPhone = info.loginPhone
    form.businessLicenceFileId = info.businessLicenceFileId
    fileValue.value.fileUrl = info.businessLicenceFileUrl
  }
})

watch(
  () => form.password,
  (n) => {
    form.towPassWord && ruleFormRef.value.validate('towPassWord')
  }
)
</script>

<style lang="scss">
.register {
  display: flex;
  flex-direction: column;
  width: 100%;
  .steps {
    width: 248px;
    margin: 0 auto;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 32px;
    .license {
      margin-bottom: 132px;
    }
    .ant-input-affix-wrapper {
      input {
        height: 24px;
      }
    }
  }
  .register-title {
    color: rgba(35, 36, 36, 1);
    font-size: 24px;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }
  .register-button {
    width: 100%;
    box-shadow: 0 9.6px 24px -7.2px #165dff80;
    background-color: #165dff;
    &.ant-btn-default {
      box-shadow: none;
    }
    &:disabled {
      color: #fff;
      box-shadow: none;
      border: none;
      background: var(--Brandblue-2, #bedaff);
    }
  }
  .register-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .safety {
      margin-right: auto;
    }
    &-box {
      height: auto;
      margin-top: 46px;
    }
    .finally-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      button {
        width: 180px;
      }
    }
  }
  .license {
    display: flex;
    width: 384px;
    height: 192px;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid var(--grey-10, #0000001a);
    background: var(--white-100, #fff);
    color: #00000040;
    font-size: 12px;
    line-height: 20px;
    &-box-title {
      color: #000000a6;
      font-size: 16px;
      line-height: 24px;
    }
    .ant-upload {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 78px;
      height: 100%;
      margin-top: 8px;
      border-radius: 8px;
      border: 1px dashed var(--grey-10, #0000001a);
      background: var(--white-100, #fff);
      cursor: pointer;
      &:hover {
        .delete-btn {
          display: block;
        }
      }
      .delete-btn {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(35, 36, 36, 0.2);
        border: 0;
        color: #fff;
        cursor: pointer;
      }
      .ant-upload-select {
        width: 100%;
        height: 100%;
        color: #165dff;
        // font-size: 16px;
      }
      .ant-upload-list {
        display: none;
      }
    }
    span.ant-upload {
      margin: 0;
      border: none;
    }
  }
  .login {
    float: right;
    line-height: 40px;
    font-size: 14px;
  }
  .get-code {
    .ant-input-group-addon {
      padding: 0;
    }
    .get-code-btn {
      border-radius: 0 6px 6px 0;
      background-color: #165dff;
    }
  }
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    // padding-left: 53px !important;
  }
  .tow-Pw {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      //   padding-left: 83px !important;
    }
  }
  .therr-text {
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      //   padding-left: 63px !important;
    }
  }
}
</style>
