import { defineStore } from 'pinia'
import type {
  SmartVendDetailType,
  StoreDetailType,
  AddStoreParams,
  UpdateStore
} from '@/types'
import { LLMSwitch, Permission, UpdateVideoFlag } from '@/types'
import { queryStoreDetailAPI, updateStoreInfoAPI } from '@/api'
// function getPreviewFileUrl(layer: Layer) {
//   switch (layer.type) {
//     case LayerTypeEnum.PICTURE:
//       return imgUrl
//       break
//     case LayerTypeEnum.VIDEO:
//       return videoUrl
//       break
//     case LayerTypeEnum.CHARACTER:
//       return (
//         modelList.value.filter(
//           (item) => item.modelId === storeDetail.value.videoTemplateVO.modelId
//         )[0]?.imgPreviewFileUrl || ''
//       )
//       break
//     default:
//       return layer.materialPreviewUrl
//   }
// }
// const imgUrl = getPreviewFileUrl(layer)
// async function queryModelList() {
//   const params = {
//     enabled: Enabled.ENABLED,
//     businessId: userInfo?.info?.businessId ?? ''
//   }
//   const res = await queryModelListAPI(params)
//   modelList.value = res.data
// }
export const useStoreStore = defineStore({
  id: 'store-info',
  state: (): {
    [key: string]: any
    addStoreInfo: AddStoreParams
    queryStoreInfo: StoreDetailType | null
    updateStoreInfo: UpdateStore
  } => ({
    addStoreInfo: {
      // storeId: '',
      llmEnable: LLMSwitch.CLOSED,
      storeName: '',
      provinceLocationId: '',
      cityLocationId: '',
      districtLocationId: '',
      storeAddress: '',
      storeDesc: '',
      storeImageId: '',
      // smartVendingMachineVOList: [],
      // districtLocationIdDesc: '',
      // updateTime: '',
      // storeStatus: '',
      videoTemplateVO: {
        // videoTemplateId: '',
        businessId: '',
        modelId: '',
        voiceId: '',
        videoTemplateName: '',
        des: '',
        permission: Permission.PUBLIC,

        layers: []
        // districtLocationIdDesc:'',
      },
      userIdList: [],
      // employeeVOList: [],
      smartVendingMachineIdList: []
    },
    updateStoreInfo: {
      storeId: '',
      llmEnable: LLMSwitch.CLOSED,
      storeName: '',
      storeImageId: '',
      storeImageUrl: '',
      provinceLocationId: '',
      cityLocationId: '',
      districtLocationId: '',
      storeAddress: '',
      storeDesc: '',
      videoTemplate: {
        businessId: '',
        modelId: '',
        voiceId: '',
        videoTemplateName: '',
        des: '',
        permission: Permission.PUBLIC,

        layers: [],
        updateVideoFlag: UpdateVideoFlag.NOT_UPDATE
      },
      smartVendingMachineIdList: [],
      userIdList: []
    },
    queryStoreInfo: null
  }),
  persist: {
    key: 'queryStoreInfo', // 自定义存储键名
    storage: localStorage,
    pick: ['queryStoreInfo', 'updateStoreInfo']
  },
  getters: {},
  actions: {
    updateSmartVendingMachineIdList(
      data: SmartVendDetailType['smartVendingMachineId']
    ) {
      this.smartVendingMachineVOList = data
    },
    updateState(key: string, value: any) {
      if (key in this.addStoreInfo) {
        //@ts-ignore
        this.addStoreInfo[key] = value
      } else {
        console.error(`Invalid key: ${key}`)
      }
    },
    updateEditorState(data: any) {
      Object.keys(data).forEach((key) => {
        if (key in this.updateStoreInfo) {
          //@ts-ignore
          this.updateStoreInfo[key] = data[key]
        } else {
          console.error(`Invalid key: ${key}`)
        }
      })
    },
    updatellmEnable(value: LLMSwitch) {
      this.updateStoreInfo.llmEnable = value
    },
    updateQueryState(data: StoreDetailType, overall: boolean = true) {
      if (overall) {
        this.queryStoreInfo = data
      } else {
        if (this.queryStoreInfo) {
          ;(
            Object.keys(
              this.queryStoreInfo as StoreDetailType
            ) as (keyof StoreDetailType)[]
          ).forEach((key) => {
            if (key !== 'videoTemplateVO')
              //@ts-ignore
              this.queryStoreInfo[key] = data[key]
          })
        }
      }
      Object.keys(this.updateStoreInfo).forEach((key) => {
        if (
          ![
            'userIdList',
            'smartVendingMachineIdList',
            'videoTemplate'
          ].includes(key)
        )
          //@ts-ignore
          this.updateStoreInfo[key] = data[key]
      })

      if (data.smartVendingMachineVOList) {
        this.updateStoreInfo.smartVendingMachineIdList =
          data.smartVendingMachineVOList.map(
            (item: any) => item.smartVendingMachineId
          )
      }
      console.log(2)

      if (data.employeeVOList) {
        this.updateStoreInfo.userIdList = data.employeeVOList.map(
          (item: any) => item.userId
        )
      }
      console.log(3)

      if (data.videoTemplateVO) {
        Object.keys(this.updateStoreInfo.videoTemplate).forEach((key) => {
          //@ts-ignore
          this.updateStoreInfo.videoTemplate[key] = data.videoTemplateVO[key]
        })
      }
    },
    async refreshStore() {
      if (!this.queryStoreInfo) return
      const res = await queryStoreDetailAPI(this.queryStoreInfo?.storeId)
      this.updateQueryState(res.data, false)
    },
    updateStore() {
      updateStoreInfoAPI(this.updateStoreInfo)
    },
    updateVideo() {
      this.updateStoreInfo.videoTemplate.updateVideoFlag =
        UpdateVideoFlag.UPDATE
    }
  }
})
