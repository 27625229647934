import type { FormValidatorFunction } from '@/basic'

export const passwordRule = {
  reg: /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/,
  prompt: {
    noInput: '请输入密码',
    errorInput: '请输入不少于8个字符的英文与数字组合'
  },
  placeholder: '请输入密码'
}
export const accountRule = {
  reg: /^.{5,20}$/,
  prompt: {
    noInput: '请输入登录账号',
    errorInput: '请输入5-20个字符登录账号'
  },
  validate: (rule: any, val: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!val) return reject('请输入登录账号')
      if (val.length < 5 || val.length > 20) {
        return reject('请输入5-20个字符')
      } else if (
        /[\u4e00-\u9fa5!@#$%^&*()+\-=\[\]{};':"\\|,.<>/?\s]/.test(val)
      ) {
        return reject('请输入正确格式数字英文下划线')
      }
      resolve()
    })
  },
  placeholder: '请输入您的登录账号'
}
