import type { RcFile } from 'ant-design-vue/es/vc-upload/interface'
import BigNumber from 'bignumber.js'
import { utils, read, writeFileXLSX, type WorkBook } from 'xlsx'
import { isImageOrVideo } from './video'

export function handleFileSizeB2MB(bytes: number) {
  const oneMegabyte = new BigNumber(1024 * 1024)
  return new BigNumber(bytes).dividedBy(oneMegabyte).toFixed(2) + 'M'
}
export function handleFileSizeMB2B(MBytes: string) {
  const mb = MBytes.split('M')[0]
  // 创建 BigNumber 实例
  const mbBN = new BigNumber(mb)
  // MB 转换为 B 的系数
  const conversionFactor = new BigNumber(1024 * 1024)
  // 进行转换
  const bytes = mbBN.multipliedBy(conversionFactor)
  return bytes.toString()
}
export function checkFileType(file: File | Blob, types?: string[]) {
  //@ts-ignore
  if (types && types.length) {
    const type = file.type.split('/')[1]
    //@ts-ignore
    return types.includes(type)
  } else return true
}
export function checkExcelFileIntegrity(
  file: File | Blob | RcFile
): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (event: ProgressEvent<FileReader>) {
      const data = event?.target?.result
      const formattedData = new Uint8Array(data as ArrayBuffer)
      const workbook = read(formattedData, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const rowCount = utils.sheet_to_json(sheet).length || 0
      try {
        resolve({ data, count: rowCount }) // 文件有效
      } catch (error) {
        reject(error) // 文件读取失败
      }
    }
    reader.onerror = function (event) {
      reject(new Error('文件损坏'))
    }
    reader.readAsArrayBuffer(file)
  })
}
export function checkFileIntegrity(
  file: File | Blob,
  ratio?: string
): Promise<
  { width: number; height: number; aspectRatio: number } | boolean | undefined
> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      if (isImageOrVideo(file.type) === 'image') {
        const img = new Image()
        img.onload = function () {
          const width = img.width
          const height = img.height
          const aspectRatio = width / height
          resolve({ width, height, aspectRatio })
        }
        img.onerror = function () {
          reject(new Error('文件损坏'))
        }
        img.src = (e.target?.result as string) || ''
      } else {
        return checkVideoIntegrity(file)
      }
    }
    reader.readAsDataURL(file)
  })
}
export function checkImageIsWidthEqualHegiht(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const width = img.width
      const height = img.height
      resolve(width === height)
    }
    img.onerror = () => {
      reject(new Error('无法加载图片'))
    }
    img.src = src
  })
}
export function checkVideoIntegrity(file: File | Blob) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = function () {
      resolve(true)
    }
    video.onerror = function () {
      reject(new Error('文件损坏'))
    }
    video.src = URL.createObjectURL(file)
  })
}
export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export function getVideoThumbnail(url: string): Promise<string> {
  const video = document.createElement('video')
  video.src = url
  // const canvas = document.getElementById(canvasElementId) as HTMLCanvasElement
  const canvas = document.createElement('canvas')
  const context = canvas?.getContext('2d')
  return new Promise((resolve, reject) => {
    video.addEventListener('loadedmetadata', function () {
      // 设置获取缩略图的时间点（这里是视频开始后的1秒）
      video.currentTime = 3
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      // 将视频帧绘制到画布上
      context?.drawImage(video, 0, 0, canvas.width, canvas.height)
      // 获取画布中的图像数据作为缩略图
      const thumbnailData = canvas.toDataURL('image/jpg')
      resolve(thumbnailData)
    })
    video.addEventListener('error', function () {
      reject('视频加载出错')
    })
  })
}
